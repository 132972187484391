import * as React from "react"
import { COMPONENT_DELAY_MS } from "../helpers/constants"
import DonateForm from "./donate-form"
import CompassionLogo from "./ui/compassion-logo"
import { gsap } from "gsap/all";
import SplitText from "gsap/SplitText";
import Reveal from "./ui/reveal";
import CloseIcon from "../images/close-icon-sand.svg"
import utils from "../helpers/utils";

const Donate = ({
  onHome
}) => {

  const donateSideRef = React.useRef()
  const gradientsRef = React.useRef()
  const donateTitleRef = React.useRef()
  const donateTopRef = React.useRef()
  const donateFormRef = React.useRef()
  const donateDescriptionRef = React.useRef()
  const closeRef = React.useRef()

  const [isAnimating, setIsAnimating] = React.useState(true)

  const SWIPE_ANIMATION_DURATION_SEC = 1.2

  let donateTitle = null

  const setupAnimation = () => {
    gsap.set(gradientsRef.current, {
      transformOrigin: "left"
    })

    gsap.set(donateSideRef.current, {
      opacity: 0
    })

    gsap.set(closeRef.current, {
      opacity: 0
    })

    gsap.set([donateTitle.words], {
      y: '100%',
      // rotate: '10deg'
    })

    gsap.set(donateTopRef.current, {
      opacity: 0
    })
    gsap.set([...donateFormRef.current.querySelectorAll('.rounded-button'), donateFormRef.current.querySelector('.donate-form-custom-input'), donateFormRef.current.querySelector('.donate-form-description')], {
      scale: 0,
      opacity: 0
    })

    gsap.set(donateFormRef.current.querySelector('.donate-tracker'), {
      y: 40,
      opacity: 0
    })

    gsap.set([donateDescriptionRef.current], {
      y: 70,
      opacity: 0
    })

    gsap.set(gradientsRef.current, {
      clipPath: 'polygon(0 0, 0 0, 0 100%, 0 100%)'
    })
    gsap.set(gradientsRef.current, {
      width: '100%'
    })
  }

  const animateIn = () => {
    // setupAnimation()
    gsap.set(donateSideRef.current, {
      opacity: 1
    })
    setTimeout(() => {
      gsap.to(closeRef.current, {
        opacity: 1,
        duration: 0.4,
        delay: 1,
        ease: "power2.inOut"
      })
      gsap.to(donateTopRef.current, {
        opacity: 1,
        duration: 0.4,
        ease: "out"
      })

      const x = {
        value: 0
      }

      const y = {
        value: 0
      }
      gsap.to(x, {
        value: 100,
        duration: SWIPE_ANIMATION_DURATION_SEC,
        ease: "power2.out",
        onUpdate: () => {
          gsap.set(gradientsRef.current, {
            clipPath: `polygon(0% 0%, ${y.value}% 0%, ${x.value}% 100%, 0% 100%)`
          })
        }
      })
      gsap.to(y, {
        value: 100,
        duration: SWIPE_ANIMATION_DURATION_SEC,
        ease: "power3.out"
      })

      gsap.to([...donateTopRef.current.querySelectorAll('.reveal-item__inner')], {
        y: 0,
        duration: 0.7,
        ease: "out",
        stagger: 0.1,
        delay: 1
      })
      gsap.to(donateTitle.words, {
        y: '0%',
        rotate: '0deg',
        // opacity: 1,
        duration: 1.2,
        stagger: 0.07,
        ease: "power4.out"
      })
      gsap.to(donateDescriptionRef.current, {
        y: 0,
        opacity: 1,
        ease: "power4.out",
        duration: 1,
        delay: 1.3
      })
      gsap.to([...donateFormRef.current.querySelectorAll('.rounded-button'), donateFormRef.current.querySelector('.donate-form-custom-input'), donateFormRef.current.querySelector('.donate-form-description')], {
        scale: 1,
        opacity: 1,
        duration: 1.2,
        ease: "power4.out",
        stagger: 0.05,
        delay: 1.4
      })
      gsap.to(donateFormRef.current.querySelector('.donate-tracker'), {
        y: 0,
        opacity: 1,
        duration: 1,
        ease: "power4.out",
        delay: 1.2
      })
    }, 500)
  }

  const animateOut = () => {
    const delay0 = 0
    const delay1 = 0.6
    const delay2 = 1.3

    const durationFast = 0.3
    const durationMedium = 0.5
    const durationLong = 0.9

    const easeLittle = "inOut"
    const easeBig = "power4.inOut"
    const easeBiggest = "Expo.inOut"

    setIsAnimating(true)

    gsap.to(donateTopRef.current, {
      opacity: 0,
      duration: durationFast,
      delay: delay1,
      ease: easeLittle
    })

    gsap.set(gradientsRef.current, {
      transformOrigin: "right"
    })

    const x = {
      value: 0
    }

    const y = {
      value: 0
    }

    gsap.to(x, {
      value: 100,
      duration: SWIPE_ANIMATION_DURATION_SEC * 0.5,
      ease: "power2.out",
      onUpdate: () => {
        gsap.set(gradientsRef.current, {
          clipPath: `polygon(${y.value}% 0%, 100% 0%, 100% 100%, ${x.value}% 100%)`
        })
      }
    })
    gsap.to(y, {
      value: 100,
      duration: SWIPE_ANIMATION_DURATION_SEC * 0.5,
      ease: "power3.out"
    })

    // gsap.to(gradientsRef.current, {
    //   clipPath: "polygon(100% 0, 100% 0, 100% 100%, 100% 100%)",
    //   opacity: 1,
    //   duration: durationLong,
    //   ease: easeBig,
    //   delay: delay0
    // })

    gsap.to([...donateTopRef.current.querySelectorAll('.reveal-item__inner')], {
      y: '-100%',
      duration: durationMedium,
      ease: easeLittle,
      stagger: 0.1,
      delay: delay1
    })

    gsap.to(donateTitleRef.current.querySelectorAll('div'), {
      y: '-110%',
      duration: durationMedium,
      stagger: 0.02,
      ease: easeBig,
      delay: delay1
    })

    gsap.to(donateDescriptionRef.current, {
      y: 0,
      opacity: 0,
      ease: easeBig,
      duration: durationFast,
      delay: delay1
    })
    gsap.to([...donateFormRef.current.querySelectorAll('.rounded-button'), donateFormRef.current.querySelector('.donate-form-custom-input'), donateFormRef.current.querySelector('.donate-form-description')], {
      scale: 0,
      opacity: 0,
      duration: durationLong,
      ease: easeBiggest,
      stagger: 0.05,
      delay: delay0
    })
    gsap.to(donateFormRef.current.querySelector('.donate-tracker'), {
      y: -40,
      opacity: 0,
      duration: durationMedium,
      ease: easeBig,
      delay: delay0
    })
  }

  const animateOutLeft = () => {
    const delay0 = 0
    const delay1 = 0.6
    const delay2 = 1.3

    const durationFast = 0.3
    const durationMedium = 0.5
    const durationLong = 0.9

    const easeLittle = "inOut"
    const easeBig = "power4.inOut"
    const easeBiggest = "Expo.inOut"

    setIsAnimating(true)

    gsap.to(closeRef.current, {
      opacity: 0,
      duration: 0.4,
      delay: 0,
      ease: "power2.inOut"
    })

    gsap.to(donateTopRef.current, {
      opacity: 0,
      duration: durationFast,
      delay: delay0,
      ease: easeLittle
    })

    gsap.set(gradientsRef.current, {
      transformOrigin: "left"
    })

    const x = {
      value: 100
    }

    const y = {
      value: 100
    }
    gsap.to(x, {
      value: 0,
      duration: SWIPE_ANIMATION_DURATION_SEC,
      ease: "power2.out",
      onUpdate: () => {
        gsap.set(gradientsRef.current, {
          clipPath: `polygon(0% 0%, ${y.value}% 0%, ${x.value}% 100%, 0% 100%)`
        })
      }
    })
    gsap.to(y, {
      value: 0,
      duration: SWIPE_ANIMATION_DURATION_SEC,
      ease: "power3.out"
    })

    // gsap.to(gradientsRef.current, {
    //   clipPath: "polygon(0% 0%, 0% 0%, 0% 100%, 0% 100%)",
    //   opacity: 1,
    //   duration: durationLong,
    //   ease: easeBig,
    //   delay: delay0
    // })

    gsap.to([...donateTopRef.current.querySelectorAll('.reveal-item__inner')], {
      y: '-100%',
      duration: durationMedium,
      ease: easeLittle,
      stagger: 0.1,
      delay: delay0
    })
    gsap.to(donateTitleRef.current.querySelectorAll('div'), {
      y: '-110%',
      // rotate: '-10deg',
      duration: durationMedium,
      stagger: 0.02,
      ease: easeBig,
      delay: delay0
    })

    gsap.to(donateDescriptionRef.current, {
      y: 0,
      opacity: 0,
      ease: easeBig,
      duration: durationFast,
      delay: delay0
    })
    gsap.to([...donateFormRef.current.querySelectorAll('.rounded-button'), donateFormRef.current.querySelector('.donate-form-custom-input'), donateFormRef.current.querySelector('.donate-form-description')], {
      scale: 0,
      opacity: 0,
      duration: durationLong,
      ease: easeBiggest,
      stagger: 0.05,
      delay: delay0
    })
    gsap.to(donateFormRef.current.querySelector('.donate-tracker'), {
      y: -40,
      opacity: 0,
      duration: durationMedium,
      ease: easeBig,
      delay: delay0
    })
  }

  const bindEvents = () => {
    document.addEventListener('closeDonationForm', () => { animateOut() })
    document.addEventListener('closeDonationFormLeft', () => {
      if (onHome) {
        animateOutLeft()
      }
    })
    document.addEventListener('openDonationForm', () => {
      setupAnimation()
      animateIn()
    })
  }

  React.useEffect(() => {
    setTimeout(() => {
      donateTitle = new SplitText(donateTitleRef.current, {
        type: "words",
        wordsClass: "origin-left"
      });
      setupAnimation()
      if (!onHome) {
        animateIn()
      }
      bindEvents()
    }, COMPONENT_DELAY_MS)
  }, [])


  return (
    <div
      ref={donateSideRef}
      className={`landing-component__donate ${onHome ? 'landing-component__donate--home' : ''}`}
      onMouseEnter={e => {
        const bounds = e.target.getBoundingClientRect();
        const x = e.clientX;
        const xPercentage = (x / gradientsRef.current.clientHeight) * 100
        gsap.set(gradientsRef.current, {
          background: `radial-gradient(117.08% 111.05% at ${xPercentage}% ${-10}%, #E8CB95 17.19%, #2460AD 59.9%)`
        })
        // setIsLeftSideActive(true)
      }}
      onMouseMove={e => {
        const bounds = e.target.getBoundingClientRect();
        const x = e.clientX;
        const y = e.clientY;
        const xPercentage = (x / gradientsRef.current.clientHeight) * 100
        const yPercentage = (y / gradientsRef.current.clientWidth) * 100
        gsap.to(gradientsRef.current, {
          background: `radial-gradient(117.08% 111.05% at ${xPercentage}% ${-10}%, #E8CB95 17.19%, #2460AD 59.9%)`,
          duration: 0.5,
          ease: "none"
        })
      }}
    >
      <div ref={donateTopRef} className="donate-top">
        <Reveal>
          <CompassionLogo isLight={true} />
        </Reveal>
      </div>
      <img ref={closeRef} onClick={() => {
        utils.ev('closeHomeDonate')
      }} className="donate-close" src={CloseIcon} alt="Close" />
      <article className="landing-component__side">
        <h1 ref={donateTitleRef}>
          <span>Donate</span><span>&nbsp;</span><span>Today,</span><br />
          <span>Restore</span><span>&nbsp;</span><span>Hope.</span>
        </h1>
        <div className="landing-component-content" ref={donateDescriptionRef}>
          <p>
            Natural disasters can take everything from those living in poverty: shelter, food, lives… and hope.
          </p>
          <p>
            But you can help. You can make a real difference.
          </p>
          <p>
            By giving today, you can help rebuild houses destroyed by cyclones in Bangladesh, provide food for locust-ravaged families in Ethiopia and restore homes and facilities in the Philippines.
          </p>
          {/* <p>
            Donate today and give hope that points others to Jesus.
          </p> */}
        </div>
      </article>
      <div ref={donateFormRef} className="w-full">
        <DonateForm />
      </div>
      <div ref={gradientsRef} className={`bg-gradient`}></div>
    </div>
  )
}

export default Donate


