import { gsap } from "gsap/all"
import * as React from "react"
import { COMPONENT_DELAY_MS } from "../../helpers/constants"

const RoundedButton = ({
  className,
  children,
  text,
  amount
}) => {

  const elRef = React.useRef()
  const marqueeRef = React.useRef()
  const maskRef = React.useRef()

  const [textTl] = React.useState(gsap.timeline({ repeat: -1 }))

  React.useEffect(() => {
    setTimeout(() => {
      const width = marqueeRef.current.getBoundingClientRect().width
      textTl.to(marqueeRef.current, {
        x: `-50%`,
        ease: "none",
        duration: width / 300,
        force3D: true,
        onComplete: () => {
          // console.log('restart')
        }
      })
    }, COMPONENT_DELAY_MS)
  }, [])

  return (
    <button ref={elRef} className="rounded-button" onClick={() => window.open(`${process.env.GATSBY_COMPASSION_CART_URL}${amount}`)}>
      <div ref={maskRef} className="rounded-button-mask"></div>
      <div ref={marqueeRef} className="rounded-button-marquee-container">
        <div>
          {text}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        </div>
        <div>
          {text}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        </div>
      </div>
      <div className="rounded-button__on">
        {children}
      </div>
    </button>
  )
}

export default RoundedButton