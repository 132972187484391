import * as React from "react"
import { COMPONENT_DELAY_MS } from "../helpers/constants"
import { StaticQuery, graphql } from "gatsby"
import { gsap } from "gsap/all"

export default function Footer({
  inline
}) {
  const footerRef = React.useRef()
  const marqueeRef = React.useRef()

  const [textTl] = React.useState(gsap.timeline({ repeat: -1 }))

  React.useEffect(() => {
    setTimeout(() => {
      const width = marqueeRef.current.getBoundingClientRect().width
      textTl.to(marqueeRef.current, {
        x: `-50%`,
        ease: "none",
        duration: width / 50,
        force3D: true
      })
    }, COMPONENT_DELAY_MS)
  }, [])

  return (
    <StaticQuery
      query={graphql`
        query HeadingQuery {
          allContentfulCrisisReport(limit: 10, sort: {fields: reportDate, order: DESC}) {
            distinct(field: reportTitle)
            nodes {
              reportTitle
              reportDate
            }
          }
        }
      `}
      render={data => (
        <footer ref={footerRef} className={`footer ${inline ? 'footer--inline' : ''}`}>
          <div ref={marqueeRef} className="footer-inner">
            {data.allContentfulCrisisReport.nodes.map((update, index) =>
              <div key={`key-update-${index}-1`}>
                {update.reportTitle}
                &nbsp;<a href="https://www.compassion.com.au/be-informed/crisis-reports" target="_blank" rel="noopener noreferrer">Read More</a>
              </div>
            )}
            {data.allContentfulCrisisReport.nodes.map((update, index) =>
              <div key={`key-update-${index}-2`}>
                {update.reportTitle}
                &nbsp;<a href="https://www.compassion.com.au/be-informed/crisis-reports" target="_blank" rel="noopener noreferrer">Read More</a>
              </div>
            )}
          </div>
        </footer>
      )}
    />
  )
}
