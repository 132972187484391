import { gsap } from "gsap/all"
import * as React from "react"
import { COMPONENT_DELAY_MS } from "../../helpers/constants"
import responsive from "../../helpers/responsive"
import utils from "../../helpers/utils"
import DonateButtonBorderCircle from '../../images/donate-button-border-circle.svg'

const CircleDonateButton = ({
  className,
  onClick
}) => {

  const elRef = React.useRef()
  const borderRef = React.useRef()
  const buttonRef = React.useRef()

  const handleButtonMove = (e) => {
    if (responsive.isDesktop()) {
      const bounds = e.target.getBoundingClientRect();
      const x = e.clientX - bounds.left;
      const y = e.clientY - bounds.top;
      const xTranslate = x - (bounds.width / 2)
      const yTranslate = y - (bounds.height / 2)
      const xSecond = e.clientX;
      const xPercentage = (xSecond / buttonRef.current.clientHeight) * 100
      gsap.set(buttonRef.current, {
        background: `radial-gradient(117.08% 111.05% at ${xPercentage}% ${-10}%, #E8CB95 17.19%, #2460AD 59.9%)`
      })


      gsap.to(elRef.current, {
        x: xTranslate / 2,
        y: yTranslate / 2,
        duration: 0.2,
        ease: "none"
      });
    }
  }

  const resetButton = () => {
    gsap.to(buttonRef.current, {
      background: `radial-gradient(117.08% 111.05% at ${50}% ${-10}%, #E8CB95 17.19%, #2460AD 59.9%)`,
      duration: 0.3,
      ease: "power2.inOut"
    })
    gsap.to(elRef.current, {
      x: 0,
      y: 0,
      scale: 1,
      ease: "elastic.out(1, 0.3)",
      duration: 0.3
    });
  }

  const handleEnter = () => {
    gsap.to(elRef.current, {
      scale: 1.1,
      ease: "elastic.out(1, 0.3)",
      duration: 0.3
    })
  }

  React.useEffect(() => {
    setTimeout(() => {
      // bindEvents()
      gsap.to(borderRef.current, {
        rotate: '360deg',
        duration: 2,
        // yoyo: true,
        ease: "Expo.inOut",
        repeat: -1
      })
    }, COMPONENT_DELAY_MS)
  }, [])

  return (
    <div ref={elRef} className="circle-donate"
      onMouseMove={handleButtonMove}
      onMouseEnter={handleEnter}
      onMouseLeave={resetButton}
      onClick={() => {
        if (onClick) {
          onClick()
        }
      }}>
      <button ref={buttonRef} className="circle-donate-button">
        Donate<br />Now
      </button>
      <svg ref={borderRef} width="151" height="151" viewBox="0 0 151 151" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect x="0.5" y="0.5" width="150" height="150" rx="75" stroke="url(#paint0_radial)" />
        <defs>
          <radialGradient id="paint0_radial" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(6.61314 29.7591) rotate(76.7014) scale(124.582)">
            <stop stopColor="#E8CF95" />
            <stop offset="1" stopColor="#2460AD" />
          </radialGradient>
        </defs>
      </svg>

    </div>
  )
}

export default CircleDonateButton