import * as React from "react"

const Reveal = React.forwardRef((props, ref) => (
  <div className={`reveal-item overflow-hidden inline-block ${props.className}`}>
    <div ref={ref} className="reveal-item__inner transform translate-y-full">
      {props.children}
    </div>
  </div>
));

export default Reveal