import { gsap } from "gsap/all"
import SplitText from "gsap/SplitText"
import * as React from "react"
import { COMPONENT_DELAY_MS } from "../../helpers/constants"

const DonateBadge = ({
  onClick
}) => {

  const elRef = React.useRef()
  const firstRef = React.useRef()
  const secondRef = React.useRef()

  let firstLetters = null
  let secondLetters = null

  React.useEffect(() => {
    setTimeout(() => {
      firstLetters = new SplitText(firstRef.current, {
        type: "chars",
        charsClass: "letter"
      });
      secondLetters = new SplitText(secondRef.current, {
        type: "chars",
        charsClass: "letter"
      });
    }, COMPONENT_DELAY_MS)
  }, [])

  return (
    <div ref={elRef} className="donate-badge"
      onMouseEnter={() => {
        gsap.set(secondLetters.chars, {
          opacity: 0
        })
        gsap.to(firstLetters.chars, {
          y: '-100%',
          ease: "power2.inOut",
          stagger: 0.01,
          duration: 0.3,
          opacity: 0
        })
        gsap.to(secondLetters.chars, {
          y: '-100%',
          ease: "power2.inOut",
          stagger: 0.01,
          duration: 0.3,
          opacity: 1
        })
      }} onMouseLeave={() => {
        gsap.to(secondLetters.chars, {
          y: '0%',
          ease: "power2.inOut",
          stagger: 0.01,
          duration: 0.3,
          opacity: 1
        })
        gsap.to(firstLetters.chars, {
          y: '0%',
          ease: "power2.inOut",
          stagger: 0.01,
          duration: 0.3,
          opacity: 1
        })

      }}>
      <div className="corner corner--top-left"></div>
      <div className="corner corner--top-right"></div>
      <div className="corner corner--bottom-left"></div>
      <div className="corner corner--bottom-right"></div>
      <div className="donate-border donate-border--top"></div>
      <div className="donate-border donate-border--bottom"></div>
      <div className="donate-border donate-border--left"></div>
      <div className="donate-border donate-border--right"></div>
      <p className="pr-4 lg:pr-0 text-mp lg:text-md">
        Give today and restore hope
      </p>
      <button className="text-mp lg:text-md relative overflow-hidden" onClick={() => {
        if (onClick) {
          onClick()
        }
      }}>
        <div ref={firstRef}>
          Donate Now
        </div>
        <div ref={secondRef} className="absolute">
          Donate Now
        </div>
      </button>
    </div>
  )
}

export default DonateBadge