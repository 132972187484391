import gsap from "gsap/all"
import * as React from "react"
import { COMPONENT_DELAY_MS } from "../helpers/constants"
import '../styles/landing-component.css'
import Reveal from "./ui/reveal"
import {
  SplitText
} from 'gsap/SplitText';
import transitions from "../helpers/transitions"
import { window } from "browser-monads"
import Donate from "./donate"
import utils from "../helpers/utils"
gsap.registerPlugin(SplitText);

const LandingComponent = () => {

  const landingRef = React.useRef()

  const disastersRef = React.useRef()
  const readMoreButtonRef = React.useRef()
  const imageOneRef = React.useRef()
  const imageTwoRef = React.useRef()
  const imageThreeRef = React.useRef()
  const disasterTopLineRef = React.useRef()
  const disasterBottomLineRef = React.useRef()
  const disasterTitleRef = React.useRef()
  const disasterDescriptionRef = React.useRef()

  // const [isAnimating, setIsAnimating] = React.useState(true)

  let isAnimating = false

  const IMAGE_ROTATION_DEG = 5;

  let circleRotateTimeout = null
  let disastersTitle = null

  const setupAnimation = () => {
    gsap.set([disasterTopLineRef.current, disasterBottomLineRef.current], {
      scaleX: 0
    })
    gsap.set(readMoreButtonRef.current, {
      scale: 0
    })
    gsap.set([imageOneRef.current, imageTwoRef.current, imageThreeRef.current], {
      bottom: '-30vw',
      opacity: 0
    })
    gsap.set([disastersTitle.words], {
      y: '100%',
      // rotate: '10deg'
    })
    gsap.set([disasterDescriptionRef.current], {
      y: 70,
      opacity: 0
    })
    // Make sure last
    gsap.set('body', {
      opacity: 1
    })
  }

  const animateIn = () => {
    if (isAnimating) {
      return
    }
    isAnimating = true
    setupAnimation()
    // Run animation
    setTimeout(() => {
      gsap.to([disasterTopLineRef.current, disasterBottomLineRef.current], {
        scaleX: 1,
        duration: 1.5,
        ease: "power4.inOut",
        stagger: 0.3,
        delay: 2
      })
      gsap.to([...disastersRef.current.querySelectorAll('.reveal-item__inner')], {
        y: 0,
        duration: 0.3,
        ease: "out",
        stagger: 0.1,
        delay: 2
      })
      gsap.to(disastersTitle.words, {
        y: '0%',
        rotate: '0deg',
        // opacity: 1,
        duration: 1.2,
        stagger: 0.07,
        ease: "power4.out",
        delay: 1.5
      })

      gsap.to(disasterDescriptionRef.current, {
        y: 0,
        opacity: 1,
        ease: "power4.out",
        duration: 1,
        delay: 2.8
      })
      gsap.to(readMoreButtonRef.current, {
        scale: 1,
        ease: "elastic.out(1, 0.3)",
        duration: 1,
        delay: 2.3,
        onComplete: () => {
          isAnimating = false
        }
      })
      gsap.to([imageOneRef.current, imageTwoRef.current, imageThreeRef.current], {
        bottom: '-15vw',
        ease: "power2.out",
        duration: 1,
        opacity: 1,
        delay: 2
      })
    }, 500)
  }

  const animateOut = () => {
    if (isAnimating) {
      return
    }

    isAnimating = true
    
    utils.ev('closeDonationForm')

    const delay0 = 0
    const delay1 = 0.6
    const delay2 = 1.3

    const durationFast = 0.3
    const durationMedium = 0.5
    const durationLong = 0.9

    const easeLittle = "inOut"
    const easeBig = "power4.inOut"
    const easeBiggest = "Expo.inOut"

    gsap.to([disasterTopLineRef.current, disasterBottomLineRef.current], {
      scaleX: 0,
      duration: durationMedium,
      ease: easeBig,
      stagger: 0.3,
      delay: delay1
    })
    gsap.to([...disastersRef.current.querySelectorAll('.reveal-item__inner')], {
      y: '-100%',
      duration: durationFast,
      ease: easeLittle,
      stagger: 0.1,
      delay: delay1
    })

    gsap.to('.landing-component__side h2 div', {
      y: '-100%',
      // rotate: '-10deg',
      duration: durationMedium,
      stagger: 0.02,
      ease: easeBig,
      delay: delay1,
      onComplete: () => {
        gsap.to(landingRef.current, {
          opacity: 0,
          duration: 0,
          ease: easeLittle,
          onComplete: () => {
            gsap.set(landingRef.current, {
              display: 'none'
            })
            transitions.animateInHome()
            // After all finished
            document.body.classList.add('has-entered')
            isAnimating = false
          }
        })
      }
    })
    gsap.to(disasterDescriptionRef.current, {
      y: 0,
      opacity: 0,
      ease: easeBig,
      duration: durationFast,
      delay: delay1
    })
    gsap.to(readMoreButtonRef.current, {
      scale: 0,
      ease: "elastic.out(1, 0.3)",
      duration: durationMedium,
      delay: delay0
    })
    gsap.to([imageOneRef.current, imageTwoRef.current, imageThreeRef.current], {
      bottom: '-30vw',
      ease: easeLittle,
      duration: durationMedium,
      opacity: 0,
      delay: delay0
    })
  }

  const onDisasterMouseLeave = (e) => {
    if (isAnimating) {
      return
    }
    gsap.to('.bg-gradient', {
      width: '100%',
      ease: "inOut",
      duration: 0.6
    })
    gsap.to(imageTwoRef.current, {
      rotate: `-2deg`,
      duration: 0.5,
      ease: "power2.inOut"
    })
    gsap.to(imageThreeRef.current, {
      rotate: `2deg`,
      duration: 0.5,
      ease: "power2.inOut"
    })
    gsap.to(readMoreButtonRef.current, {
      left: '50%',
      top: '70%',
      duration: 0.8,
      rotate: `0deg`,
      ease: "inOut"
    })
    gsap.to([imageOneRef.current, imageTwoRef.current, imageThreeRef.current], {
      bottom: '-15vw',
      duration: 0.5,
      ease: "power2.inOut"
    })
  }

  const onDisasterMouseEnter = (e) => {
    if (isAnimating) {
      return
    }
    gsap.to('.bg-gradient', {
      width: '95%',
      ease: "inOut",
      duration: 0.6
    })
    gsap.to([imageOneRef.current, imageTwoRef.current, imageThreeRef.current], {
      bottom: '-10vw',
      duration: 0.5,
      ease: "power2.inOut"
    })
    gsap.to(imageTwoRef.current, {
      rotate: `-${IMAGE_ROTATION_DEG}deg`,
      duration: 0.5,
      ease: "power2.inOut"
    })
    gsap.to(imageThreeRef.current, {
      rotate: `${IMAGE_ROTATION_DEG}deg`,
      duration: 0.5,
      ease: "power2.inOut"
    })
  }

  const onDisasterMouseMove = (e) => {
    if (isAnimating) {
      return
    }
    const bounds = e.target.getBoundingClientRect();
    const x = e.clientX - bounds.left;
    // setLastX(x)
    let rotation = e.movementX
    // if (x < lastX) {
    //   rotation = 12
    // } else if (x > lastX) {
    //   rotation = -12
    // }
    // console.log(lastX, x)
    const y = e.clientY - bounds.top;
    gsap.to(readMoreButtonRef.current, {
      left: x,
      top: y,
      duration: 0.5,
      rotate: `${rotation}deg`,
      ease: "power1.out",
    })
    window.clearTimeout(circleRotateTimeout)
    circleRotateTimeout = setTimeout(() => {
      gsap.to(readMoreButtonRef.current, {
        duration: 1,
        rotate: `0deg`,
        ease: "power1.out",
      })
    }, 500)
  }

  React.useEffect(() => {
    setTimeout(() => {
      disastersTitle = new SplitText(disasterTitleRef.current, {
        type: "words",
        wordsClass: "origin-left"
      });
      animateIn()
    }, COMPONENT_DELAY_MS)
  }, [])


  return (
    <section ref={landingRef} className="landing-component">
      <Donate />
      <div
        ref={disastersRef}
        className={`landing-component__disasters`}
        onMouseEnter={e => {
          onDisasterMouseEnter()
        }}
        onMouseMove={e => onDisasterMouseMove(e)}
        onMouseLeave={e => onDisasterMouseLeave(e)}
        onClick={() => {
          animateOut()
        }}
      >
        <button ref={readMoreButtonRef} className="read-more-circle-button">Read More</button>
        <div className="disaster-top pointer-events-none">
          <div ref={disasterTopLineRef} className="disaster-top-line origin-center"></div>
          <div className="grid grid-cols-3 gap-4 text-sm pt-3">
            <Reveal>
              Issue #034
            </Reveal>
            <Reveal className="text-center">
              01.06.21
            </Reveal>
            <Reveal className="text-right">
              Compassion Australia
            </Reveal>
          </div>
        </div>
        <article className="landing-component__side pointer-events-none">
          <h2 ref={disasterTitleRef}>
            <span>The</span><span>&nbsp;</span><span>Year</span><span>&nbsp;</span>
            <span>Of</span><span>&nbsp;</span><span>Disasters</span>
          </h2>
          <div ref={disasterDescriptionRef}>
            <p>
              In a year of COVID-19, earthquakes and floods, 2020 was not done with the Philippines yet. November brought two more typhoons just two weeks apart: Goni, then Vamco. According to the United Nations, 7.5 million people were affected, 425,000 homes were damaged or destroyed and 98 people were killed.
            </p>
          </div>
        </article>
        <div ref={disasterBottomLineRef} className="disaster-top-line origin-center absolute bottom-6 left-0 mx-6"></div>
        <div ref={imageOneRef} className="image-one"></div>
        <div ref={imageTwoRef} className="image-two"></div>
        <div ref={imageThreeRef} className="image-three"></div>
      </div>

    </section>
  )
}

export default LandingComponent