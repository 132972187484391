export const scroll = {
  // Locomotive Scroll
  // https://github.com/locomotivemtl/locomotive-scroll#instance-options
  container: "#scrollarea",
  options: {
    smooth: true,
    smoothMobile: false,
    getDirection: true,
    gestureDirection: 'both',
    getSpeed: true,
    tablet: {
      smooth: true,
      direction: 'horizontal',
      horizontalGesture: true
    },
    smartphone: {
      smooth: false,
      direction: 'vertical'
    },
    lerp: 0.05,
    reloadOnContextChange: true,
    direction: 'horizontal'
  },
}
