import { window } from "browser-monads"
import gsap from "gsap/gsap-core"
import * as React from "react"
import { COMPONENT_DELAY_MS } from "../helpers/constants"
import Axios from "axios"

function callWebservice(callback) {
  const donationEndpointUrlFromConfig = process.env.GATSBY_SALESFORCE_WEBSERVICE_ENDPOINT;
  Axios.get(donationEndpointUrlFromConfig)
    .then(function(response) {
      if (response.data && response.data.data) {
        callback(response.data.data.DonationAmount);
      }
    }
  );
}

const DonationTracker = ({
  className,
  isSmall
}) => {

  const elRef = React.useRef()
  const titleRef = React.useRef()
  const subtitleRef = React.useRef()

  const DEFAULT_INTERVAL_MS = 3000;
  const TIME_BETWEEN_WEBSERVICE_CHECKS_SECONDS = 60;
  const TARGET_APPEAL_AMOUNT = 1600000;


  const titles = ['$-', '-%']
  const subtitles = ['Raised so far', 'of goal reached']

  const [activeIndex, setActiveIndex] = React.useState(0)
  const [title, setTitle] = React.useState(titles[activeIndex])
  const [subtitle, setSubtitle] = React.useState(subtitles[activeIndex])
  const [currentDonatedAmount, setCurrentDonatedAmount] = React.useState('$-');
  const [currentDonatedPercentage, setCurrentDonatedPercentage] = React.useState('-%');
  const [webserviceCallCount, setWebserviceCallCount] = React.useState(0);

  const activeIndexRef = React.useRef();
  activeIndexRef.current = activeIndex;
  const currentDonatedAmountRef = React.useRef();
  currentDonatedAmountRef.current = currentDonatedAmount;
  const currentDonatedPercentageRef = React.useRef();
  currentDonatedPercentageRef.current = currentDonatedPercentage;

  const animateNewValues = () => {
    gsap.to([titleRef.current, subtitleRef.current], {
      opacity: 0,
      duration: 0.3,
      ease: "power2.inOut",
      onComplete: () => {
        setTitle(activeIndexRef.current === 0 ? currentDonatedAmountRef.current : currentDonatedPercentageRef.current)
        setSubtitle(subtitles[activeIndexRef.current])
        gsap.set(titleRef.current, {
          y: '100%',
        })
        gsap.set(subtitleRef.current, {
          y: '-100%'
        })

        gsap.to([titleRef.current, subtitleRef.current], {
          y: '0%',
          opacity: 1,
          duration: 0.5,
          ease: "power2.inOut"
        })
      }
    })
  }

  React.useEffect(() => {
    const timeout = setTimeout(() => {
      if (activeIndexRef.current === titles.length - 1) {
        setActiveIndex(0)
      } else {
        setActiveIndex(activeIndexRef.current + 1)
        // console.log('active index update')
      }
      animateNewValues()
    }, DEFAULT_INTERVAL_MS)
    return () => {
      window.clearTimeout(timeout)
    }
  }, [activeIndex])

  const getCurrentDonatedAmount = (isFirstRun) => {
    setWebserviceCallCount(webserviceCallCount + 1);
    //console.log(webserviceCallCount);
    callWebservice((amount) => {
      const currencyFormatter = new Intl.NumberFormat('en-AU', {
        style: 'currency',
        currency: 'AUD',
        maximumFractionDigits: 0,
        minimumFractionDigits: 0
      });

      const currentDonationAmountRaw = parseFloat(amount);
      const currentDonationAmount = currencyFormatter.format(currentDonationAmountRaw);
      const currentPercentageRaised = ((currentDonationAmountRaw / TARGET_APPEAL_AMOUNT) * 100).toFixed(0) + '%';
      setCurrentDonatedAmount(currentDonationAmount);
      setCurrentDonatedPercentage(currentPercentageRaised);

      if (isFirstRun) {
        setTitle(activeIndexRef.current === 0 ? currentDonationAmount : currentPercentageRaised)
      }
    });
  }

  React.useEffect(() => getCurrentDonatedAmount(true), []); // initial call, run once and immediately set title with result
  React.useEffect(() => {
    const timer = setTimeout(getCurrentDonatedAmount, 1000 * TIME_BETWEEN_WEBSERVICE_CHECKS_SECONDS);
    return () => clearTimeout(timer);
  }, [webserviceCallCount]);

  return (
    <div ref={elRef} className={`donation-tracker ${isSmall ? 'donation-tracker--small' : ''} | inline-block flex-flex-col text-center ${className}`}>
      <div className="overflow-hidden">
        <h2 ref={titleRef}>
          {title}
        </h2>
      </div>
      <hr className={`${isSmall ? 'my-0' : 'my-2'} opacity-50`} />
      <div className="overflow-hidden">
        <p className="subtext text-center" ref={subtitleRef}>
          {subtitle}
        </p>
      </div>
    </div>
  )
}

export default DonationTracker