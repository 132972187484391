import Scene from "./Scene"
import Scrollbar from "smooth-scrollbar"
import OverscrollPlugin from "smooth-scrollbar/plugins/overscroll"
import { TweenMax as TM } from "gsap/all"
import HorizontalScrollPlugin from "../helpers/horizontalScrollPlugin"
import responsive from "../helpers/responsive"
import utils from "../helpers/utils"

Scrollbar.use(HorizontalScrollPlugin, OverscrollPlugin)

export default class Stage {
  constructor() {
    this.$els = {
      scene: document.getElementById("scene"),
    }

    this.scrolledPastHalf = false

    this.init()

    this.bindEvents()
  }

  resize() {
    if (!responsive.isDesktop()) {
      Scrollbar.destroyAll()
    }
  }

  bindEvents() {
    document.addEventListener("lockScroll", ({ detail }) => {
      this.lockScroll(detail)
    })
    document.addEventListener("resize", () => {
      this.resize()
    })
  }

  init() {
    Scrollbar.destroyAll()
    if (responsive.isDesktop()) {
      this.Scroll = Scrollbar.init(document.querySelector("#scrollarea"), {
        delegateTo: document,
        continuousScrolling: false,
        overscrollEffect: "bounce",
        damping: 0.05,
        plugins: {
          horizontalScroll: {
            events: [],
          },
        },
      })

      this.Scroll.scrollTo(0, 0, 0)

      this.Scroll.track.xAxis.element.remove()
      this.Scroll.track.yAxis.element.remove()

      this.Scroll.addListener(status => {
        if (!this.scrolledPastHalf) {
          if (status.offset.x >= window.innerWidth / 4) {
            utils.ev("scrolledPastHalf")
            this.scrolledPastHalf = true
          }
        } else {
          if (status.offset.x < window.innerWidth / 4) {
            utils.ev("scrolledBackIntoHalf")
            this.scrolledPastHalf = false
          }
        }
      })

      Scrollbar.detachStyle()
      this.scene = new Scene(this.$els.scene)
    }
  }

  /* Actions
  --------------------------------------------------------- */

  lockScroll({ lock }) {
    const duration = lock ? 0 : 1.8

    TM.delayedCall(duration, () => {
      this.Scroll.updatePluginOptions("horizontalScroll", {
        events: lock ? [] : [/wheel/],
      })
    })
  }
}
