import { navigate } from "gatsby-link"
import { gsap } from "gsap/all"
import * as React from "react"
import { COMPONENT_DELAY_MS } from "../../helpers/constants"
import transitions from "../../helpers/transitions"
import utils from "../../helpers/utils"
import ArrowRightSand from '../../images/arrow-right-sand.svg'

const LineItem = ({
  position,
  title,
  to
}) => {

  const elRef = React.useRef()
  const maskRef = React.useRef()

  const ANIMATION_DURATION_SEC = 0.6

  const x = {
    value: 100
  }

  const y = {
    value: 100
  }

  // clip-path: polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%);
  // clip-path: polygon(100% 0, 100% 0, 100% 100%, 100% 100%);

  const [hoverTl] = React.useState(
    gsap.timeline({ paused: true })
      .to(x, {
        value: 0,
        duration: ANIMATION_DURATION_SEC,
        ease: "power1.out",
        onUpdate: () => {
          gsap.set(maskRef.current, {
            clipPath: `polygon(0 100%, 100% 100%, 100% ${x.value}%, 0% ${y.value}%)`
          })
        }
      })
      .to(y, {
        value: 0,
        duration: ANIMATION_DURATION_SEC,
        ease: "power4.out"
      }, 0)
  )

  const [isAnimating, setIsAnimating] = React.useState(false)
  const [isFinished, setIsFinished] = React.useState(true)


  React.useEffect(() => {
    setTimeout(() => {
    }, COMPONENT_DELAY_MS)
  }, [])


  const handleMouseEnter = () => {
    hoverTl.restart().play()
    // hoverTl.play()
  }

  const handleMouseLeave = () => {
    hoverTl.reverse()
  }

  const handleClick = e => {
    e.preventDefault()
    transitions.genericPageTransition(() => {
      navigate(to)
    })
  }

  return (
    <a onClick={handleClick} href={to || '/'} ref={elRef} className="relative flex-1 w-screen flex flex-col block"
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}>
      <div ref={maskRef} className="line-item-mask"></div>
      <div className="flex items-center flex-1 px-12 justify-between">
        <div className="flex items-center">
          <div className="w-12 h-12 relative flex items-center justify-center text-center">
            <div className="relative" style={{
              zIndex: 2
            }}>
              {position}
            </div>
            <svg className="absolute top-0 left-0" version="1.1" viewBox="0 0 500 500" preserveAspectRatio="xMinYMin meet">
              <circle cx="250" cy="250" r="200" stroke={utils.getSandHex()} strokeWidth="10" />
            </svg>
          </div>
          <h2 className="text-xl pl-8">
            {title}
          </h2>
        </div>
        <div>
          <img src={ArrowRightSand} alt="Arrow Right" />
        </div>
      </div>
      <hr />
    </a>

  )
}

export default LineItem