import * as THREE from "three"
import { TweenMax as TM, Power2, Power3 } from "gsap/all"
import vertexShader from "../glsl/vertexShader.glsl"
import { SplitText as ST } from "gsap/SplitText"
import utils from "../helpers/utils"
import responsive from "../helpers/responsive"
import Scrollbar from "smooth-scrollbar"
import { navigate } from "gatsby"
import gsap from "gsap/gsap-core"
import { Power1 } from "gsap/gsap-core"
import { window } from "browser-monads"
import { Power4 } from "gsap/gsap-core"

export default class Tile {
  constructor($el, scene, duration, fragmentShader) {
    this.scene = scene
    this.$els = {
      body: document.body,
      el: $el,
      link: $el,
      text: $el.querySelector(".dynamic-image-group__text"),
      title: $el.querySelector(".dynamic-image-group__title"),
    }

    this.duration = duration

    this.mainImage = this.$els.el.querySelector("img")
    this.images = []
    this.sizes = new THREE.Vector2(0, 0)
    this.offset = new THREE.Vector2(0, 0)

    this.vertexShader = vertexShader
    this.fragmentShader = fragmentShader

    this.clock = new THREE.Clock()

    this.mouse = new THREE.Vector2(0, 0)

    this.scroll = 0
    this.prevScroll = 0
    this.delta = 0
    this.hasClicked = false
    this.isHovering = true

    this.loader = new THREE.TextureLoader()

    this.preload([this.mainImage.src, this.mainImage.dataset.hover], () => {
      this.initTile()
    })

    this.Scroll = Scrollbar.get(document.querySelector("#scrollarea"))

    this.bindEvent()
  }

  bindEvent() {
    document.addEventListener("tile:zoom", ({ detail }) => {
      this.zoom(detail)
    })
    window.addEventListener("resize", () => {
      this.onResize()
    })
    window.addEventListener("mousemove", e => {
      this.onMouseMove(e)
    })
    this.$els.link.addEventListener("click", e => {
      this.onClick(e)
    })

    if (this.Scroll) {
      this.Scroll.addListener(s => {
        this.onScroll(s)
      })
    }
  }

  /* Handlers
  --------------------------------------------------------- */

  onClick(e) {
    e.preventDefault()

    if (responsive.isMobile()) return

    if (!this.mesh) return

    this.hasClicked = true

    utils.ev("toggleDetail", {
      open: true,
      target: this,
    })
  }

  onResize() {
    this.uniforms.u_res.value.set(window.innerWidth, window.innerHeight)

    this.getBounds()

    if (!this.mesh) return

    TM.set(this.mesh.position, {
      x: this.offset.x,
      y: this.offset.y,
    })
    this.mesh.scale.set(this.sizes.x, this.sizes.y, 1)
  }

  onScroll({ offset, limit }) {
    this.scroll = offset.x / limit.x
  }

  onMouseMove(event) {
    if (this.hasClicked || responsive.isMobile()) return

    TM.to(this.mouse, 0.5, {
      x: event.clientX,
      y: event.clientY,
    })
  }

  /* Actions
  --------------------------------------------------------- */

  initTile() {
    this.stgs = new ST(this.$els.title, { type: "words", wordsClass: "word" })

    this.stgs.words.forEach(word => {
      const div = document.createElement("div")
      div.classList.add("word-container")
      utils.wrap(word, div)
    })

    const texture = this.images[0]
    const hoverTexture = this.images[1]

    this.getBounds()

    this.uniforms = {
      u_alpha: { value: 1 },
      u_map: { type: "t", value: texture },
      u_ratio: { value: utils.getRatio(this.sizes, texture.image) },
      u_hovermap: { type: "t", value: hoverTexture },
      u_hoverratio: { value: utils.getRatio(this.sizes, hoverTexture.image) },
      u_shape: { value: this.images[2] },
      u_mouse: { value: this.mouse },
      u_progressHover: { value: 1 },
      u_progressClick: { value: 0 },
      u_time: { value: this.clock.getElapsedTime() },
      u_res: {
        value: new THREE.Vector2(window.innerWidth, window.innerHeight),
      },
      u_textureSize: {
        value: new THREE.Vector2()
      }
    }

    this.geometry = new THREE.PlaneBufferGeometry(1, 1, 1, 1)

    this.material = new THREE.ShaderMaterial({
      uniforms: this.uniforms,
      vertexShader: this.vertexShader,
      fragmentShader: this.fragmentShader,
      transparent: true,
      defines: {
        PI: Math.PI,
        PR: window.devicePixelRatio.toFixed(1),
      },
    })

    this.mesh = new THREE.Mesh(this.geometry, this.material)

    this.mesh.position.x = this.offset.x
    this.mesh.position.y = this.offset.y

    this.mesh.scale.set(this.sizes.x, this.sizes.y, 1)

    this.scene.mainScene.add(this.mesh)

    this.mainImage.classList.add("is-loaded")
  }

  move() {
    if (!this.mesh || this.hasClicked) return
    this.getBounds()
    TM.set(this.mesh.position, {
      x: this.offset.x,
      y: this.offset.y,
    })
  }

  update() {
    if (!this.mesh) return
    this.delta = Math.abs((this.scroll - this.prevScroll) * 20000)
    this.move()
    this.prevScroll = this.scroll
    this.uniforms.u_time.value += this.clock.getDelta()
  }

  zoom({ tile, open }) {
    const shouldZoom = tile === this && open

    const scaledHeight = window.innerHeight - 0.31 * window.innerHeight
    const scaledWidth = window.innerWidth * 0.3

    const newScl = {
      x: shouldZoom ? scaledWidth : this.sizes.x,
      y: shouldZoom ? scaledHeight : this.sizes.y,
    }

    const scaledPositionX =
      -(window.innerWidth / 2) + scaledWidth / 2 + window.innerHeight * 0.03
    const scaledPositionY =
      -(window.innerHeight / 2) + scaledHeight / 2 + window.innerHeight * 0.03

    const newPos = {
      x: shouldZoom ? scaledPositionX : this.offset.x,
      y: shouldZoom ? scaledPositionY : this.offset.y,
    }
    const newRatio = utils.getRatio(newScl, this.images[1].image)
    const delay = shouldZoom ? 0.4 : 0

    if (shouldZoom) {
      this.$els.el.closest("section").classList.add("is-animating-section")
      const allOtherElements = document
        .querySelector("#scrollarea .scroll-content")
        .querySelectorAll(
          "section:not(.is-animating-section), .section:not(.is-animating-section)"
        )
      const currentNonTitleElements = document
        .querySelector(
          "#scrollarea .scroll-content section.is-animating-section"
        )
        .querySelectorAll(".hide-on-animate")

      const elementsToHide = [...allOtherElements, ...currentNonTitleElements]

      TM.to(elementsToHide, 0.6, {
        opacity: 0,
        ease: Power2.easeInOut,
      })
      TM.to(this.uniforms.u_hoverratio.value, 1.4, {
        delay,
        x: newRatio.x,
        y: newRatio.y,
        ease: "power4.inOut",
      })
      TM.to(this.mesh.scale, 1.4, {
        delay,
        x: newScl.x,
        y: newScl.y,
        ease: "power4.inOut",
        onUpdate: () => {
          this.getBounds()
        },
      })

      TM.to(this.uniforms.u_progressClick, 1.4, {
        value: 1,
        ease: "power4.inOut",
        onComplete: () => {
          this.hasClicked = true
        },
      })

      TM.to(this.mesh.position, 1.4, {
        delay,
        x: newPos.x,
        y: newPos.y,
        ease: "power4.inOut",
        onComplete: () => {
          TM.to("#scene", 0.3, {
            opacity: 0,
            ease: Power1.easeInOut,
          })
          TM.to("body", 0.3, {
            backgroundColor: utils.getSandHex(),
            duration: 0.3,
            ease: Power1.easeInOut,
            onComplete: () => {
              navigate(this.$els.el.dataset.to)
            },
          })
        },
      })

      gsap.to(".circle-donate-container", {
        opacity: 0,
        ease: Power2.easeInOut,
        duration: 0.3,
      })

      TM.to(".footer", 1, {
        yPercent: 100,
        ease: Power2.easeInOut,
        force3D: true,
      })

      TM.to(".nav", 1, {
        yPercent: -100,
        ease: Power2.easeInOut,
        force3D: true,
      })

      TM.staggerTo(
        this.stgs.words,
        1,
        {
          yPercent: -110,
          ease: Power4.easeInOut,
          force3D: true,
        },
        0.35 / this.stgs.words.length
      )
    } else {
      const delay = 0
      TM.to(this.uniforms.u_alpha, 0.5, {
        delay,
        value: 0,
        ease: Power3.easeIn,
      })

      TM.to(this.$els.el, 0.5, {
        delay,
        alpha: 0,
        force3D: true,
      })
    }
  }

  /* Values
  --------------------------------------------------------- */

  getBounds() {
    const { width, height, left, top } = this.mainImage.getBoundingClientRect()

    this.sizes.set(width, height)
    this.offset.set(
      left - window.innerWidth / 2 + width / 2,
      -top + window.innerHeight / 2 - height / 2
    )
  }

  preload($els, allImagesLoadedCallback) {
    let loadedCounter = 0
    const toBeLoadedNumber = $els.length
    const preloadImage = ($el, anImageLoadedCallback) => {
      const image = this.loader.load($el, anImageLoadedCallback)
      image.center.set(0.5, 0.5)
      this.images.push(image)
    }

    $els.forEach($el => {
      preloadImage($el, () => {
        loadedCounter += 1
        if (loadedCounter === toBeLoadedNumber) {
          allImagesLoadedCallback()
        }
      })
    })
  }
}
