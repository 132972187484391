import { document } from "browser-monads"
import gsap from "gsap/gsap-core"
import * as React from "react"
import { COMPONENT_DELAY_MS } from "../../helpers/constants"
import ScrollDown from '../../images/scroll-down-to-explore.svg'

const ScrollCircle = () => {

  const elRef = React.useRef()
  const circleRef = React.useRef()
  const cursorRef = React.useRef()

  const bindEvents = () => {
    document.addEventListener('scrolledPastHalf', () => {
      gsap.to(elRef.current, {
        opacity: 0,
        duration: 1,
        scale: 0.5,
        ease: "power2.inOut"
      })
    })
    document.addEventListener('scrolledBackIntoHalf', () => {
      gsap.to(elRef.current, {
        opacity: 1,
        duration: 1,
        scale: 1,
        ease: "power2.inOut"
      })
    })
  }

  React.useEffect(() => {
    bindEvents()
    setTimeout(() => {
      gsap.to(circleRef.current, {
        rotate: '360deg',
        ease: "none",
        duration: 12,
        repeat: -1
      })
      gsap.to(cursorRef.current, {
        y: '1vh',
        opacity: 0,
        ease: "power1.inOut",
        repeat: -1,
        duration: 1.6,
        delay: 2
      })
    }, COMPONENT_DELAY_MS)
  }, [])

  return (
    <div ref={elRef} className="scroll-circle">
      {/* <div className="scroll-circle-mask"></div> */}
      <div className="scroll-circle-mouse">
        <div ref={cursorRef} className="scroll-circle-cursor"></div>
      </div>
      <img ref={circleRef} src={ScrollDown} alt="Scroll to explore" />
    </div>
  )
}

export default ScrollCircle