import * as React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import OGImage from "../images/og.png"
import LandingComponent from "../components/landing-component"
import Footer from "../components/footer"
import Breadcrumb from "../components/ui/breadcrumb"
import Scroll from '../components/scroll'
import GoniImage from '../images/Goni/goni1.jpg'
import GoniImage2 from '../images/Goni/goni2.jpg'
import AmphanImage from '../images/Amphan/amphan2.jpg'
import AmphanImage2 from '../images/Amphan/amphan3.jpg'
import VamcoImage from '../images/Vamco/vamco1.jpg'
import VamcoImage2 from '../images/Goni/goni2.jpg'
import TrainingImage from '../images/Training/training1.jpg'
import TrainingImage2 from '../images/Training/training2.jpg'
import ReadMore from "../components/ui/read-more"
import BoyVideo from '../videos/boy-video2.mp4'
import DonateBadge from "../components/ui/donate-badge"
import VerticalImageRotator from "../components/ui/vertical-image-rotator"
import LineItem from "../components/ui/line-item"
import ScrollCircle from "../components/ui/scroll-circle"
import { useMediaQuery } from "react-responsive"
import responsive from "../helpers/responsive"
import LocustsImage from '../images/Locusts/locusts1.jpg'
import LocustsImage2 from '../images/Locusts/locusts2.jpg'
import Share from "../components/ui/share"
import utils from "../helpers/utils"
import { COMPONENT_DELAY_MS } from "../helpers/constants"
import Stage from "../js/Stage"
import { gsap } from "gsap/all"
import Nav from "../components/nav"
import transitions from "../helpers/transitions"
import { SplitText as ST } from 'gsap/SplitText'
import { window } from "browser-monads"
import CircleDonateButton from '../components/ui/circle-donate-button'
import Donate from "../components/donate"
import SplitText from "gsap/SplitText"
import { navigate } from "gatsby-link"
import { Power1 } from "gsap/gsap-core"
import { Power2 } from "gsap/gsap-core"
import ScrollTrigger from "gsap/ScrollTrigger";
import curtains from "../helpers/curtains"

gsap.registerPlugin(ScrollTrigger)
gsap.core.globals('ScrollTrigger', ScrollTrigger)

const IndexPage = () => {

  let stage = null
  let titleWords = null
  let locustsWords = null

  const titleRef = React.useRef()
  const firstContentRef = React.useRef()
  const donateShadowRef = React.useRef()
  const circleDonateButtonRef = React.useRef()
  const locustsTitleRef = React.useRef()

  // const [isDonateOpen, setIsDonateOpen] = React.useState(false)

  let isDonateOpen = false
  // const [isAnimatingDonateForm, setIsAnimatingDonateForm] = React.useState(false)
  let isAnimatingDonateForm = false

  const setupAnimation = () => {

    if (!responsive.isDesktop()) {
      return
    }

    locustsWords = new SplitText(locustsTitleRef.current, { type: 'words', wordsClass: 'word' })

    locustsWords.words.forEach((word) => {
      const div = document.createElement('div')
      div.classList.add('word-container')
      utils.wrap(word, div)
    })

    utils.hideBody()

    titleWords = new ST(titleRef.current, { type: 'words', wordsClass: 'word' })

    titleWords.words.forEach((word) => {
      const div = document.createElement('div')
      div.classList.add('word-container')
      utils.wrap(word, div)
    })

    gsap.set(titleWords.words, {
      yPercent: 110,
      // rotate: '10deg'
    })

    gsap.set([...firstContentRef.current.querySelectorAll('p, .fcbc'), '.scroll-circle, canvas'], {
      opacity: 0
    })

    gsap.set('.circle-donate', {
      scale: 0,
      opacity: 1
    })

    gsap.set('#scene', {
      opacity: 0,
      y: 0
    })

    gsap.set('.first-image-selector', {
      opacity: 0
    })

  }

  const setupMobileAnimation = () => {
    gsap.set('.circle-donate', {
      scale: 0
    })
  }

  const animateIn = () => {
    if (!responsive.isDesktop()) {
      return
    }
    setupAnimation()
    utils.showBody()
    if (utils.enteredSite()) {
      transitions.animateInHome()
    }
  }

  const animateInMobile = () => {
    if (responsive.isDesktop()) {
      return
    }
    setupMobileAnimation()
    utils.showBody()
    transitions.animateInNavAndFooter()
    ScrollTrigger.create({
      trigger: ".landing-component",
      start: "bottom top",
      onEnter: () => {
        transitions.animateInCircleButton()
      },
      onEnterBack: () => {
        console.log('back!')
        transitions.animateOutCircleButton()
      }
    })
  }

  const closeDonateForm = () => {
    if (isAnimatingDonateForm) {
      return
    }
    isAnimatingDonateForm = true
    utils.ev('closeDonationFormLeft')

    gsap.to(donateShadowRef.current, {
      opacity: 0,
      duration: 0.5,
      delay: 0.5,
      ease: "power4.inOut",
      onComplete: () => {
        isDonateOpen = false
        utils.unlockScroll()
        gsap.to('.circle-donate', {
          opacity: 1,
          ease: "none",
          duration: 0.3
        })
        isAnimatingDonateForm = false
        gsap.set('.landing-component__donate--home', {
          pointerEvents: 'none'
        })
        gsap.set(donateShadowRef.current, {
          pointerEvents: 'none'
        })
      }
    })
  }

  const openDonateForm = () => {
    if (isAnimatingDonateForm) {
      return
    }
    isAnimatingDonateForm = true
    utils.ev('openDonationForm')
    utils.lockScroll()
    gsap.to('.circle-donate', {
      opacity: 0,
      ease: "none",
      duration: 0.3
    })
    gsap.to(donateShadowRef.current, {
      opacity: 0.7,
      duration: 0.5,
      delay: 0.5,
      ease: "power4.inOut",
      onComplete: () => {
        gsap.set('.landing-component__donate--home', {
          pointerEvents: 'auto'
        })
        isAnimatingDonateForm = false
        gsap.set(donateShadowRef.current, {
          pointerEvents: 'auto'
        })
        isDonateOpen = true
      }
    })
  }

  const toggleDonateForm = () => {
    if (isDonateOpen) {
      closeDonateForm()
    } else {
      openDonateForm()
    }
  }

  const bindEvents = () => {
    document.addEventListener('closeHomeDonate', () => {
      closeDonateForm()
    })
  }

  React.useEffect(() => {
    utils.setTheme('dark')
    utils.hideBody()
    // bindEvents()
    setTimeout(() => {
      bindEvents()
      animateIn()
      animateInMobile()
      // curtains.initPlanes()
      setTimeout(() => {
        stage = new Stage()
      }, 1000)
    }, COMPONENT_DELAY_MS)
  }, [])

  const onClickLocusts = (e) => {

    e.preventDefault()

    gsap.to(locustsWords.words, {
      duration: 1,
      yPercent: -110,
      // rotate: '-10deg',
      ease: "power4.inOut",
      force3D: true,
      stagger: 0.35 / locustsWords.words.length
    })

    transitions.genericPageTransition(() => {
      navigate('/disaster/locust-plagues')
    })
  }


  return (
    <Layout>
      <SEO
        title="Restore Hope Appeal | Compassion Australia"
        description="When you give to Compassion's Disaster Relief fund, you provide the resources, support and services needed to help when disaster strikes. Whether children in poverty are affected by storms in the Philippines, locust swarms in Ethiopia or cyclones in Bangladesh, your generous support can provide them with what they need and restore their hope. Hope that points them to Jesus."
        image={OGImage}
      />
      <Donate onHome={true} />
      <div ref={donateShadowRef} onClick={() => {
        closeDonateForm()
      }} className="donate-shadow"></div>
      <div className="circle-donate-container" ref={circleDonateButtonRef}>
        <CircleDonateButton onClick={() => {
          toggleDonateForm()
        }} />
      </div>
      <Nav inverse={false} />
      <LandingComponent />
      <main id="scrollarea" style={{
        opacity: 0
      }}>
        <section className="section flex flex-col justify-between first-scroll section--first">
          <div className="flex flex-grow items-center relative text-md">
            <h2 ref={titleRef} className="home-title">The year of<br /> disasters</h2>
            <div className="absolute -right-8 transform rotate-90 lg:rotate-0">
              <ScrollCircle />
            </div>
          </div>
          <div ref={firstContentRef} className="first-content | double-column lg:ml-48">
            <Breadcrumb className="fcbc mb-4" leftText="Restore Hope Appeal" rightText="Info" />
            <p>
              2020 brought earthquakes, floods, volcanic eruptions, typhoons and COVID-19.
            </p>
            <p>
              The Philippines—the third most disaster-prone country in the world—felt the impact of them all.
            </p>
            <p>
              At the end of 2020, the country was hit by one of the strongest typhoons in its history: Goni. The United Nations reports that at least 25 people were killed, almost 300,000 homes were damaged or destroyed and nearly 3 million people were affected. Flooding and landslides washed away or damaged homes, school and roads.
            </p>
            <p>
              Then, just over a week later, typhoon Vamco followed, killing 73 people and destroying 150,000 homes. Due to COVID-19, evacuation centres could not be filled to capacity, leaving many people with nowhere to go.
            </p>
            <p>
              Between both typhoons, nearly 2000 Compassion assisted children were affected, leaving many without shelter. Our Child Sponsorship Program provides them with care and support, but when disasters occur, more is needed to protect not just one child, but all of them. Children living in poverty are among the most vulnerable people in their community and disasters only serve to further expose them to hardship.
            </p>
            <p>
              But you can help restore hope.
            </p>
            <p>
              When you give to the Restore Hope Appeal, you provide the resources, support and services needed to help when disaster strikes. Whether children in poverty are affected by storms in the Philippines, locust swarms in Ethiopia or cyclones in Bangladesh, your generous support can provide them with what they need and restore their hope.
              </p>

          </div>
        </section>

        <Footer inline={true} />

        <section className="dynamic-image-group section lg:flex" data-to="/disaster/typhoon-goni">
          <figure className="plane first-image-selector | figure">
            <img className="figure-image" src={GoniImage} data-hover={GoniImage} alt="Typhoon Goni" crossOrigin="" />
          </figure>
          <article className="lg:max-w-xl pt-6 lg:pt-0 lg:pl-12 flex items-end">
            <div>
              <h2 className="dynamic-image-group__title | text-mh2 lg:text-4xl">
                Typhoon Goni Devastates
              </h2>
              <Breadcrumb className="my-5 lg:my-4 hide-on-animate" leftText="PHILIPPINES – TYPHOON" rightText="1 NOV 2020" />
              <div className="dynamic-image-group__text | article-text hide-on-animate">
                <p>
                  Typhoon Goni made landfall on November 1 and devastated a number of provinces, including locations where Compassion local partners work. Many sponsored children were forced flee to evacuation areas, but COVID-19 remained a threat. Houses made from light materials, common in the area, were significantly damaged or completely destroyed.
                </p>
                <p>
                  In one location, over 10 families sought shelter inside a church before the entire roof was ripped off by strong winds. Others had their homes submerged or washed away in the floods.
                </p>
                <ReadMore className="hide-on-animate" to="/disaster/typhoon-goni" />
              </div>
            </div>
          </article>
        </section>

        <section className="section">
          <div className="flex flex-col">
            <article className="dynamic-image-group | lg:flex flex-1 max-h-half" data-to="/disaster/cyclone-vamco">
              <figure className="plane figure figure--sm">
                <img className="figure-image" src={VamcoImage} data-hover={VamcoImage} alt="Cyclone Vamco" crossOrigin="" />
              </figure>
              <div className="lg:max-w-lg flex items-center lg:pl-12 pt-6 lg:pt-0">
                <div>
                  <h2 className="dynamic-image-group__title | text-mh2 lg:text-2xl lg:pb-3">Vamco Displaces Thousands</h2>
                  <Breadcrumb className="my-5 lg:my-4 hide-on-animate" leftText="PHILIPPINES – CYCLONE" rightText="11 NOV 2020" />
                  <p className="dynamic-image-group__text hide-on-animate">
                    Vamco was the 21st tropical cyclone to hit the Philippines in 2020 and arrived only two weeks after Typhoon Goni. It forced thousands of sponsored children into crowded, wet and uncomfortable evacuation centres. The homes of more than 700 sponsored children across 10 local partners were damaged or ruined.
                  </p>
                  <ReadMore className="hide-on-animate" to="/disaster/cyclone-vamco" />
                </div>
              </div>
            </article>
            <hr className="divider divider--no-line hide-on-animate" />
            <article className="dynamic-image-group | lg:flex flex-1 max-h-half" data-to="/disaster/training-for-the-future">
              <figure className="plane figure figure--sm">
                <img className="figure-image" src={TrainingImage} data-hover={TrainingImage} alt="Training For The Future" />
              </figure>
              <div className="lg:max-w-lg flex items-center lg:pl-12 pt-6 lg:pt-0">
                <div>
                  <h2 className="dynamic-image-group__title | text-mh2 lg:text-2xl lg:pb-3">Training For The Future</h2>
                  <Breadcrumb className="my-5 lg:my-4 hide-on-animate" leftText="PHILLIPPINES - TRAINING" rightText="2020" />
                  <p className="dynamic-image-group__text hide-on-animate">
                    Knowing the frequent natural disasters that hit the Philippines, one Compassion child development centre organised disaster preparedness training for all the children and interested parents.
                  </p>
                  <ReadMore className="hide-on-animate" to="/disaster/training-for-the-future" />
                </div>
              </div>
            </article>
          </div>
        </section>

        <div className="section section--full relative video-bars hidden lg:flex">
          <video className="video" playsInline autoPlay muted loop onCanPlayThrough={() => {
            setTimeout(() => {
              if (window.locomotiveScroll) {
                window.locomotiveScroll.update()
              }
            }, 500)
          }}>
            <source src={BoyVideo} type="video/mp4" />
          </video>
        </div>

        <section className="section flex flex-col justify-between">
          <div className="mb-12 lg:mb-0">
            <DonateBadge onClick={() => {
              openDonateForm()
            }} />
          </div>
          <figure className="plane figure figure--sm block lg:hidden">
            <img className="figure-image" src={LocustsImage} alt="Cyclone Vamco" />
          </figure>
          <article className="lg:max-w-xl flex items-end pt-8 lg:pt-0">
            <div>
              <h2 ref={locustsTitleRef} className="text-mh2 lg:text-3xl">
                Church Fights Local Plagues
              </h2>
              <Breadcrumb className="my-5 lg:my-4" leftText="ETHIOPIA – LOCUSTS" rightText="2020" />
              <div className="article-text">
                <p className="padding-fix-mobile-locusts">
                  Swarms of ravenous locusts blacken the sky, ready to devour anything they find on the ground. Once they descend, months of hard work are ravaged within hours. Nothing is left, not even fodder for the cattle.
                  </p>
                <p className="hidden lg:block">
                  Locust invasions in Ethiopia are a devastating blow for farmers, who labour daily to ensure food security for themselves, and to the economy, already shaken by COVID-19. As the agriculture bureau conducts aerial sprays of pesticide to kills locusts, the neighbouring farmers were warned to harvest their crops quickly before the locusts reached them.
                  </p>
                <ReadMore to="/disaster/locust-plagues" onClick={onClickLocusts} />
              </div>
            </div>
          </article>
        </section>

        <section className="section hidden lg:flex section--skew">
          <div style={{
            transform: `skewX(-5deg)`,
            // paddingRight: `15vh`
          }}>
            <VerticalImageRotator />
          </div>
        </section>

        <div className="section max-w-7xl hidden lg:flex">
          <div className="flex flex-col justify-between pl-32 pr-14">
            <h2 className="text-5xl italic capitalize">
              “Grateful for<br />every breath..."
              </h2>
            <div className="text-lg text-left">
              Cyril, Compassion Sponsored Child
                </div>
          </div>

        </div>


        <section className="dynamic-image-group | section flex-col lg:max-w-7xl" data-to="/disaster/cyclone-amphan">
          <div className="lg:pr-12 flex flex-col flex-1">
            <figure className="plane figure figure--vertical">
              <img className="figure-image" src={AmphanImage} data-hover={AmphanImage} alt="Cyclone Amphan" />
            </figure>
            <article className="lg:flex items-center flex-1 pt-8 lg:pt-0">
              <h2 className="dynamic-image-group__title | text-mh2 lg:text-3xl flex-1">Super-cyclone Amphan Makes Landfall</h2>
              <Breadcrumb className="my-5 lg:my-4 lg:hidden" leftText="BANGLADESH – CYCLONES" rightText="20 MAY 2020" />
              <div className="flex-1 hide-on-animate">
                <p className="dynamic-image-group__text">
                  Super-cyclone Amphan—the Bay of Bengal’s fiercest storm this century—struck India first on the evening of May 20, trampling everything in its path before reaching the coast of southern Bangladesh. Power supplies were cut, homes were destroyed and more than 80 people lost their lives.
                </p>
                <ReadMore to="/disaster/cyclone-amphan" />
              </div>
            </article>
          </div>
        </section>

        <div className="section section--no-padding">
          <div className="vertical-divider"></div>
          <div className="flex flex-col justify-between">
            <div className="flex-1 flex flex-col hidden lg:flex" style={{
              maxHeight: '65vh'
            }}>
              <LineItem position="1" title="Typhoon Goni “Devastates”" to="/disaster/typhoon-goni" />
              <LineItem position="2" title="Vamco Displaces Thousands" to="/disaster/cyclone-vamco" />
              <LineItem position="3" title="Training For The Future" to="/disaster/training-for-the-future" />
              <LineItem position="4" title="Church Fights Locust Plague" to="/disaster/locust-plagues" />
              <LineItem position="5" title="Super-Cyclone Amphan Makes Landfall" to="/disaster/cyclone-amphan" />
            </div>
            <hr className="divider block lg:hidden" />
            <div className="self-end pr-4 lg:p-12">
              <Share title="Restore Hope Appeal | Compassion Australia" url="https://compassionappeal.znstudio.com.au" />
            </div>
          </div>
        </div>
      </main>
      <canvas id="scene"></canvas>
      <Footer />
    </Layout >
  )
}

export default IndexPage
