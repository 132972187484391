import * as React from "react"
import { COMPONENT_DELAY_MS } from "../helpers/constants"
import DonationTracker from "./donation-tracker"
import RoundedButton from "./ui/rounded-button"
import { IMaskInput } from "react-imask";
import Arrow from "../images/arrow-right-black.svg"

const DonateForm = () => {

  const elRef = React.useRef()

  const [customDonationAmount, setCustomDonationAmount] = React.useState('Other')

  const currencyFormatter = new Intl.NumberFormat("en-AU", { style: "currency", currency: "AUD", minimumFractionDigits: 2 });

  React.useEffect(() => {
    setTimeout(() => {
    }, COMPONENT_DELAY_MS)
  }, [])

  return (
    <div ref={elRef} className="text-center pt-8 w-full pb-8 mx-auto">

      <DonationTracker className="mb-8 donate-tracker" />
      {/* <div className="donate-form-description | text-sand my-4 font-gotham uppercase font-bold text-center text-xs bg-canary py-3 max-w-xs mx-auto">
        <div className="text-black">
          Mouse-over to discover the impact your donations can make.
        </div>

      </div> */}
      <div className="grid grid-cols-3 gap-4 pt-4 px-6">
        <RoundedButton text="Help supply flood and locust affected families in Ethiopia with a two-month food basket" amount="70">$70</RoundedButton>
        <RoundedButton text="Help repair a family’s home after a typhoon" amount="250">$250</RoundedButton>
        <RoundedButton text="Help repair typhoon-damaged classrooms at Compassion Centres" amount="2000">$2,000</RoundedButton>
        {/* <div className="block lg:hidden flex-1 w-full">
        </div> */}
      </div>
      <div className="grid grid-cols-2 lg:grid-cols-3 gap-4 mt-4 px-6">
        <RoundedButton text="Help build a family a new toilet" amount="2500">$2,500</RoundedButton>
        <RoundedButton text="Help build a family a disaster resilient home" amount="6500">$6,500</RoundedButton>
        {/* <div className="hidden lg:block flex-1 w-full">
        </div> */}
        <div className="col-span-2 lg:col-span-1 donate-form-custom-input | flex items-center">
          <div className="flex flex-1 items-center border-b-2 border-white font-medium mr-3 pb-1">
            <span className="text-md mr-4">$</span>
            <IMaskInput
              placeholder="Other"
              value={customDonationAmount}
              unmask={true}
              name="donationAmount"
              className="w-full text-lg font-medium outline-none border-none placeholder-sand py-3"
              style={{ maxWidth: "7rem", background: "transparent" }}
              mask={Number}
              radix="."
              scale={2}
              thousandsSeparator=","
              min={1}
              onAccept={(value, mask) => setCustomDonationAmount(value)} />
          </div>
          <button
            rel="noreferrer"
            onClick={() => {
              if (customDonationAmount && customDonationAmount !== 'Other') {
                window.open(`${process.env.GATSBY_COMPASSION_CART_URL}${customDonationAmount}`) } 
              }
            }
              
            className="track-give-now uppercase bg-canary text-black px-8 py-3 rounded-full text-md"
            disabled={customDonationAmount && customDonationAmount !== 'Other' ? null : 'disabled'}
          >
            <img src={Arrow} />
          </button>
        </div>
      </div>
    </div>
  )
}

export default DonateForm