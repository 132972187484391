import { gsap } from "gsap/all"
import * as React from "react"
import { COMPONENT_DELAY_MS } from "../../helpers/constants"

import Image1 from '../../images/Locusts/locusts1.jpg'
import Image2 from '../../images/Locusts/locusts2.jpg'
import Image3 from '../../images/Locusts/locusts3.jpg'
import Image4 from '../../images/Locusts/locusts4.jpg'

const VerticalImageRotator = () => {

  const elRef = React.useRef()

  const [imageTl] = React.useState(gsap.timeline({ repeat: -1 }))


  React.useEffect(() => {
    setTimeout(() => {
      imageTl.to(elRef.current, {
        y: `-50%`,
        ease: "none",
        duration: 30,
        force3D: true
      })
    }, COMPONENT_DELAY_MS)
  }, [])

  return (
    <div ref={elRef} className="flex flex-col px-12">
      <div className="flex flex-col">
        <figure className="image-rotator-image">
          <img src={Image1} alt="Locusts" />
        </figure>
        <figure className="image-rotator-image">
          <img src={Image2} alt="Locusts" />
        </figure>
        <figure className="image-rotator-image">
          <img src={Image3} alt="Locusts" />
        </figure>
        <figure className="image-rotator-image">
          <img src={Image4} alt="Locusts" />
        </figure>
      </div>
      <div className="flex flex-col">
        <figure className="image-rotator-image">
          <img src={Image1} alt="Locusts" />
        </figure>
        <figure className="image-rotator-image">
          <img src={Image2} alt="Locusts" />
        </figure>
        <figure className="image-rotator-image">
          <img src={Image3} alt="Locusts" />
        </figure>
        <figure className="image-rotator-image">
          <img src={Image4} alt="Locusts" />
        </figure>
      </div>
    </div>
  )
}

export default VerticalImageRotator