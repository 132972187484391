import * as React from "react"
import utils from "../../helpers/utils"
import { COMPONENT_DELAY_MS } from "../../helpers/constants"
import { Link, navigate } from "gatsby"
import SplitText from "gsap/SplitText"
import { gsap } from "gsap/all"
import transitions from "../../helpers/transitions"
import responsive from "../../helpers/responsive"

const ReadMore = ({
  to,
  className,
  onClick
}) => {

  const elRef = React.useRef()
  const firstRef = React.useRef()
  const secondRef = React.useRef()

  let firstLetters = null
  let secondLetters = null

  React.useEffect(() => {
    setTimeout(() => {
      firstLetters = new SplitText(firstRef.current, {
        type: "chars",
        charsClass: "letter"
      });
      secondLetters = new SplitText(secondRef.current, {
        type: "chars",
        charsClass: "letter"
      });
    }, COMPONENT_DELAY_MS)
  }, [])

  return (
    <a onClick={(e) => {
      if (responsive.isDesktop()) {
        e.preventDefault()
        return
      }
      if (onClick) {
        onClick(e)
      } else {
        e.preventDefault()
        transitions.genericPageTransition(() => {
          navigate(to)
        })
      }
    }} href={to || '/'} className={`read-more-link ${className}`} onMouseEnter={() => {
      gsap.set(secondLetters.chars, {
        opacity: 0
      })
      gsap.to(firstLetters.chars, {
        y: '-100%',
        ease: "power2.inOut",
        stagger: 0.01,
        duration: 0.3,
        opacity: 0
      })
      gsap.to(secondLetters.chars, {
        y: '-100%',
        ease: "power2.inOut",
        stagger: 0.01,
        duration: 0.3,
        opacity: 1
      })
    }} onMouseLeave={() => {
      gsap.to(secondLetters.chars, {
        y: '0%',
        ease: "power2.inOut",
        stagger: 0.01,
        duration: 0.3,
        opacity: 1
      })
      gsap.to(firstLetters.chars, {
        y: '0%',
        ease: "power2.inOut",
        stagger: 0.01,
        duration: 0.3,
        opacity: 1
      })

    }}>
      <div ref={firstRef}>
        Read More
      </div>
      <div ref={secondRef} className="absolute">
        Read More
      </div>
    </a>
  )
}

export default ReadMore